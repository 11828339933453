import React from 'react'
import { images } from 'config/images'

export const ProductDevServicesProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Software product development services for startups and{' '}
        <span className="heading-red">successful tech businesses</span>
      </>
    ),
    bannerContent: (
      <>
        We partner with entrepreneurs and businesses
        <br /> to shape and validate their product ideas
      </>
    ),
    BannerAlt: 'Software product development services',
    buttonName: 'Get Free Consultation',
    buttonLink: '/contact/',
  },
  customDevProps: {
    serviceHeading: (
      <>
        <span className="highlight-head">Digital product</span> <br />{' '}
        development services
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Product Discovery & Design Services',
        serviceParagraph: (
          <>
            Replace guesswork with <strong>product discovery</strong> and proven
            processes. We create a strategic canvas and work with you to
            understand user flows and jobs to be done.
          </>
        ),
        serviceIcon: images.sproductDiscovery,
        serviceAlt: 'Product',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'UX Design & UI Development Services',
        serviceParagraph: (
          <>
            Use data to understand what customers do and why they do it.{' '}
            <strong>Intuitive user flows</strong> are created that make users
            jobs easier. Our creative developers build interactions to bring the
            vision to life.
          </>
        ),
        serviceIcon: images.suxDesign,
        serviceAlt: 'UX',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Rapid Prototype Building Services',
        serviceParagraph: (
          <>
            We are obsessed with getting it right. We{' '}
            <strong>build prototypes</strong> from research insights and bring
            users into the process early to validate, iterate, and optimize
            towards your vision.
          </>
        ),
        serviceIcon: images.sprogressiveWebApp,
        serviceAlt: 'Rapid',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'Apps Development Services',
        serviceParagraph: (
          <>
            Bringing product designs to life is a challenge for many companies.
            We have a track record of <strong>architecting for scale</strong>{' '}
            and building products through the iterative development process.
          </>
        ),
        serviceIcon: images.sappDevService,
        serviceAlt: 'Apps',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: 'Product Transformation & Re-engineering',
        serviceParagraph: (
          <>
            Upgrade your product to richer UX or new architecture enabling SaaS
            delivery. We offer{' '}
            <strong>hassle-free product transformation</strong> services
            ensuring minimal impact on existing users.
          </>
        ),
        serviceIcon: images.sproductTransformation,
        serviceAlt: 'Product',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Usability Testing Services',
        serviceParagraph: (
          <>
            Learn more about your existing product or your competition’s with{' '}
            <strong>usability testing.</strong> Our team of expert researchers
            will help you plan, manage, and moderate tests.
          </>
        ),
        serviceIcon: images.sautomationTesting,
        serviceAlt: 'Usability',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  buildApplicationProps: {},

  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of working with
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'The right way to build products',
        workingBenefitsContent: (
          <>
            Our services are tailored to meet the needs of each client. We apply
            <strong>digital product methodologies</strong>, including rapid
            prototyping, ongoing usability testing, and iterative development to
            bridge the gap between the digital experiences of users and their
            physical ones.
          </>
        ),
        benefitImgAlt: 'Building products',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsContent: (
          <>
            The result is an <strong>innovative user experience</strong> that
            offers consumers something relevant, useful, and on-brand—and is
            something that drives business goals. Designs, prototypes and
            working builds are continuously reviewed and refined.
          </>
        ),
        benefitImgAlt: 'Design prototypes',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Discovery Sprints',
        workingBenefitsList: [
          'Competitive Analysis',
          'Sketches',
          'Mood Boards',
          'Existing Product Audit',
          'User Flows',
          'Low-Fi Prototypes',
          'Information Architecture',
        ],
        benefitImgAlt: 'Discovery sprints',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Product Design Sprints',
        workingBenefitsList: [
          'Wireframes',
          'User Interface Designs',
          'Style Guide',
          'Hi-fi Prototypes',
          'Logo Design',
          'Product Requirements',
        ],
        benefitImgAlt: 'wireframe',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Development Sprints',
        workingBenefitsList: [
          'Continuous Builds',
          'Proof of Concepts',
          'Beta Builds',
          'Test Cases',
          'Automated Test Scripts',
          'Load Testing',
          'Launch Plans',
        ],
        benefitImgAlt: 'Development sprints',
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Portfolio of <span className="highlight-head">software products</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What our <span className="highlight-head">customers say</span>
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  developSoftAgileProps: {
    heading: (
      <>
        How we develop
        <br />
        <span className="highlight-head">software products</span>
      </>
    ),
    headingLeftImgProps: [
      {
        src: images.serviceUser2,
        srcSet: images.serviceUser22x,
      },
      {
        src: images.serviceUser3,
        srcSet: images.serviceUser32x,
      },
      {
        src: images.serviceUser1,
        srcSet: images.serviceUser12x,
      },
    ],
    headingRightImgProps: [
      {
        src: images.serviceUser4,
        srcSet: images.serviceUser42x,
      },
      {
        src: images.serviceUser5,
        srcSet: images.serviceUser52x,
      },
      {
        src: images.serviceUser6,
        srcSet: images.serviceUser62x,
      },
    ],
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.ServiceSprintPlanning,
        workFlowIconAlt: 'Team',
        workFlowTitle: 'Ideation',
        workFlowParagraph:
          'We seek to understand users’ problems. We leverage various techniques like user interviews, surveys, and even stakeholder interviews.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.ServiceTechArchitecture,
        workFlowIconAlt: 'Design',
        workFlowTitle: 'Design',
        workFlowParagraph:
          'Monolithic apps are broken into microservices. Decoupling the code allows teams to move faster and more independently.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.ServiceStandups,
        workFlowIconAlt: 'Tech',
        workFlowTitle: 'Build',
        workFlowParagraph:
          'We design flexible and scalable designs keeping tech capacity in mind. Every interaction is empathetically designed keeping our end users in mind.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '4',
        workFlowIcon: images.ServiceCodeReviews,
        workFlowIconAlt: 'StandUps',
        workFlowTitle: 'Measure and grow',
        workFlowParagraph:
          'Growth strategies are baked into the design, KPIs are set. We test, measure and feed learnings back into iteration cycles of product building.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
    ],
  },
  SimformGuaranteeData: {
    buildApplicationHeading: (
      <>
        Leading companies <span className="highlight-head">trust us</span> to
        build their products
      </>
    ),
    buildAppPara: (
      <>
        We <span className="color-through">add development capacity</span> to
        tech teams when agility is needed. Our value isn’t limited to building
        teams but is equally distributed across the project lifecycle. We own
        and guarantee the successful delivery of your project.
      </>
    ),
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle: 'What is a custom software product development?',
        faqPara: (
          <>
            Custom software development is the development of software products
            for the specific needs of a business process or group of users. Such
            a product serves the goals of a business by providing features and
            workflows generally not found in more traditional and widespread
            off-the-shelf software.
          </>
        ),
      },
      {
        faqTitle: 'How much does software development cost?',
        faqPara: (
          <>
            In our experience, many custom software projects fall somewhere
            between the $50,000 and $500,000 mark to design and develop.
            <br />
            <br />
            It’s a broad range, and probably not that useful because the
            software can mean anything from a calculator on your phone to a full
            enterprise billing system that supports millions of users. No two
            custom-built applications are the same.
            <br />
            <br />
            Biggest Factors for Software Development Cost:
            <br />
            <br />
            <ul>
              <li>Size of project and number of features</li>
              <li>Complexity</li>
              <li>Third-party integrations</li>
              <li>Design complexity</li>
              <li>Technology stack</li>
              <li>Developer’s skillset</li>
              <li>Velocity required</li>
              <li>Development Process</li>
            </ul>
            <br />
            Typically application development only represents 35% of the total
            cost. The ongoing maintenance cost for medium-sized enterprise apps
            ranges from $3,000 to $11,000 per month.
          </>
        ),
      },

      {
        faqTitle:
          'What technologies will be used to develop a software product?',
        faqPara: (
          <>
            We are technology neutral and strongly believe that each technology
            has its own pros and cons. It depends on the skills of the
            developers rather than the technology itself.
            <br /> <br />
            We work on a range of technologies, frameworks, and programming
            languages for both server-side and front-end side.
            <br /> <br />
            Backend - Node.js, .Net Core, asp.Net (C#), PHP, Python, Java, Ruby
            On Rails, Go
            <br /> <br />
            Frontend - JavaScript, TypeScript, Angular, React, Vue, iOS,
            Android, Electron, Unity, WPF, React Native, Flutter, Xamarin
            <br /> <br />
            Databases - PostgresQL, SQL Server, MySQL, Oracle, Firebase,
            Firestore, MongoDB, DynamoDB, Couchbase, Cassandra, Hbase, Redis,
            ElasticSearch, RethinkDB, Cosmos DB, Neptune, Hadoop, Hortonworks,
            MAPR, Cloudera, etc.
            <br /> <br />
            CMS - Liferay, Sitecore, Magento, Shopify, Wordpress, Moodle,
            Umbraco, Canvas, Adapt, Docebo, Litmos, Successfactors, Totara, etc.
            <br /> <br />
            Cloud & Integrations - Salesforce, Sharepoint, Amazon AWS, Microsoft
            Azure, Google Cloud, Kubernetes, Docker, Mulesoft, Pivotal, Algolia,
            Apigee, Splunk, Talend, Nexmo, Twilio, Alfresco, Stripe etc.
            <br /> <br />
            Testing - Selenium, Saucelabs, Appium, Mocha, Katalon, SoapUI,
            Gatling, JMeter, Hoverfly
            <br /> <br />
            DevOps - Jenkins, CircleCI, TravisCI, Codeship, Gradle
          </>
        ),
      },

      {
        faqTitle: 'What are your product development process steps?',
        faqPara: (
          <>
            Here’s how we will work with you for user-oriented product -
            <br />
            <ul>
              <li>Test Cases Driven User Stories for Clear Requirements</li>
              <li>Intense Sprint Planning</li>
              <li>Iterative Delivery</li>
              <li>Documentation</li>
              <li>Constant Communication and Retrospective</li>
              <li>Code review</li>
              <li>Integrating QA in the development process</li>
              <li>CI-CD and Automating Deployment</li>
              <li>Post-release</li>
            </ul>
            <br />
            <br />
            It’s important to circle back and review how the process went once
            you’re done, be it a success or failure.
          </>
        ),
      },

      {
        faqTitle: 'How do you choose the right tech architecture?',
        faqPara: (
          <>
            That is not an easy answer, and like many other answers related to
            software development, it depends. We follow the concept of
            Evolutionary Architectures.
            <br /> <br />
            We picture your project as a set of different modules, each module
            can vary in complexity and requirements. Therefore each module can
            have a proper architecture that best suits it. Despite that, we have
            to think about architecture as living beings, so they will adapt and
            change during the project.
            <br /> <br />
            Choose a design for the whole system upfront usually is not the best
            idea. We would recommend you to picture your system as this set of
            modules and start to choose a design that best addresses each module
            needs, always favoring simplicity.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/home-automation-using-internet-of-things/',
        featurecardTitle:
          'Home Automation Using the Internet of Things (IoT) – 2019',
      },
      {
        blogLink:
          'https://www.simform.com/make-gps-vehicle-fleet-tracking-system-hardware/',
        featurecardTitle:
          'How to Make a GPS tracking device for Vehicle and Fleet Tracking',
      },
      {
        blogLink:
          'https://www.simform.com/blog/custom-software-development-costs/',
        featurecardTitle:
          'How to Estimate Custom Software Development Costs for your Projects?',
      },
    ],
  },
}
