import React, { Component } from 'react'
import { DevelopSoftSection } from './DevelopSoftAgile.styles'
import { images } from 'config/images'
import Picture from 'components/Picture/Picture'

class DevelopSoftAgile extends Component {
  render() {
    const { developSoftAgileProps } = this.props.developSoftAgiledata
    return (
      <DevelopSoftSection className="developsoft-section">
        <div className="develop-software-heading-block">
          <div className="left-heading-block">
            {developSoftAgileProps.headingLeftImgProps.map(
              (headingLeftImgProp, i) => {
                return (
                  <Picture
                    key={i}
                    src={headingLeftImgProp.src}
                    srcSet={headingLeftImgProp.srcSet}
                    alt=""
                  />
                )
              }
            )}
          </div>
          <h2>{developSoftAgileProps.heading}</h2>
          <div className="right-heading-block">
            {developSoftAgileProps.headingRightImgProps.map(
              (headingRightImgProp, i) => {
                return (
                  <Picture
                    key={i}
                    src={headingRightImgProp.src}
                    srcSet={headingRightImgProp.srcSet}
                    alt=""
                  />
                )
              }
            )}
          </div>
        </div>
        <div className="section-para">
          <p>{developSoftAgileProps.sectionPara}</p>
        </div>
        <div className="work-flow-listing">
          {developSoftAgileProps.workFlowFirstRows.map(workFlowFirstRow => {
            return (
              <div
                key={workFlowFirstRow.workFlowCount}
                className={`single-workflow ${workFlowFirstRow.workFlowClass}`}
              >
                <div className="workflow-no">
                  {workFlowFirstRow.workFlowCount}
                </div>
                <div className="img-block">
                  <img
                    src={workFlowFirstRow.workFlowIcon}
                    alt={workFlowFirstRow.workFlowIconAlt}
                  />
                </div>
                <h6>{workFlowFirstRow.workFlowTitle}</h6>
                <p>{workFlowFirstRow.workFlowParagraph}</p>
                <div className="arrow-img">
                  <img src={workFlowFirstRow.workFlowArrowImg} alt="" />
                </div>
              </div>
            )
          })}
          <img src={images.connector} alt="" className="connector-img" />
        </div>
      </DevelopSoftSection>
    )
  }
}

export default DevelopSoftAgile
