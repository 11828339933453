import styled from 'styled-components'
import { lg, headingFont, bodyFont, xl, md, sm, sh } from 'config/variables'

export const DevelopSoftSection = styled.section`
  .develop-software-heading-block {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    ${xl(`
      flex-wrap: wrap;
    `)}
    ${lg(`
      flex-wrap: wrap;
    `)}
    .left-heading-block {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      ${xl(`
        flex: initial;
        margin-right: 7px;
      `)}
      ${lg(`
        flex: initial;
        margin-right: 7px;
      `)}
      ${md(`
        display: none;
      `)}
      picture {
        margin-right: -15px;
        ${sh(`
          margin-right: -35px;
          transform: scale(0.85);
        `)}
        img{
          max-width: 124px;
        }
      }
    }
    h2 {
      width: 100%;
      max-width: 682px;
      text-align: center;
      ${sh(`
        max-width: 620px;
      `)}
      ${xl(`
        order: -1;
        max-width: 100%;
        margin-bottom: 30px;
      `)}
      ${lg(`
        order: -1;
        max-width: 100%;
        margin-bottom: 30px;
      `)}
      ${md(`
        padding: 0 15px;
      `)}
    }
    .right-heading-block {
      display: flex;
      justify-content: flex-start;
      flex: 1;
      ${xl(`
        flex: initial;
        margin-left: 7px;
      `)}
      ${lg(`
        flex: initial;
        margin-left: 7px;
      `)}
      ${md(`
        display: none;
      `)}
      picture {
        margin-left: -15px;
        ${sh(`
          margin-left: -35px;
          transform: scale(0.85);
        `)}
        img{
          max-width: 124px;
        }
      }
    }
  }
  .section-para {
    display: block;
    margin: 0 auto;
    max-width: 700px;
    margin-bottom: 26px;
    text-align: center;
    ${sh(`
      max-width: 670px;
    `)}
    p {
      font-size: 25px;
      line-height: 34px;
      ${sh(`
        font-size: 23px;
        line-height: 32px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 26px;
        padding: 0 15px;
      `)}
    }
  }
  .work-flow-listing {
    position: relative;
    margin: 0 auto;
    padding-top: 15px;
    display: flex;
    justify-content: center;
    ${lg(`
      flex-wrap: wrap;
    `)}
    ${md(`
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow: auto;
    `)}
    .single-workflow {
      position: relative;
      max-width: 283px;
      width: 100%;
      padding: 15px 20px;
      margin: 0 15px;
      ${lg(`
        max-width: 330px;
        margin-bottom: 30px;
      `)}
      ${md(`
        max-width: 283px;
        min-width: 283px;
        margin-right: 0px;
        margin-left: 20px;
        margin-bottom: 0px;
      `)}
      ${sm(`
        max-width: 250px;
        min-width: 250px;
        `)}
      &:before {
        content: '';
        height: 10px;
        width: 10px;
        border: 2px solid #dce6fb;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        margin: 0px auto;
        background: $white;
        display: none;
      }
      &:first-child:before {
        display: none;
      }
      &.very-light-blue {
        border: 1px solid #dce6fb;
        .workflow-no {
          color: #8d7fd8;
          background: #dce6fb;
        }
        &:before {
          border-color: #dce6fb;
        }
      }
      &.duck-egg-blue {
        border: 1px solid #d1edeb;
        .workflow-no {
          color: #57aea8;
          background: #d1edeb;
        }
        &:before {
          border-color: #d1edeb;
        }
      }
      &.light-peach {
        border: 1px solid #f2dbcb;
        .workflow-no {
          color: #cf8f63;
          background: #f2dbcb;
        }
        &:before {
          border-color: #f2dbcb;
        }
      }
      &.light-peach-three {
        border: 1px solid #f6e5c4;
        .workflow-no {
          color: #d2a95a;
          background: #f6e5c4;
        }
        &:before {
          border-color: #f6e5c4;
        }
        .arrow-img {
          display: none;
        }
      }
      .workflow-no {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 0;
        right: 0;
        font-size: 18px;
        line-height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${headingFont};
        font-weight: 700;
      }
      .img-block {
        width: 50px;
        height: 50px;
        margin-bottom: 7px;
        ${sh(`
          width: 45px;
          height: 45px;
        `)}
        img {
          display: block;
        }
      }
      h6 {
        font-size: 18px;
        line-height: 25px;
        font-family: ${bodyFont};
        margin-bottom: 4px;
        font-weight: 600;
        ${sh(`
          font-size: 17px;
          line-height: 24px;
        `)}
      }
      p {
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        ${sh(`
          font-size: 14px;
          line-height: 19px;
        `)}
      }
      .arrow-img {
        width: 15px;
        height: 39px;
        position: absolute;
        top: 50%;
        right: -20px;
        margin-top: -8px;
        transform: rotate(-90deg);
        ${md(`
          display: none;
        `)}
      }
      ${lg(`
      &:nth-child(2) {
        .arrow-img {
          left: 0px;
          top: auto;
          right: 0px;
          bottom: -31px;
          transform: rotate(0deg);
          margin: 0 auto;
        }
      }
      &:nth-child(3) {
        order: 3;
        .arrow-img {
          left: -20px;
          right: auto;
          transform: rotate(90deg);
        }
      }
      `)}
      ${md(`
        &:nth-child(3) {
        order: 0;
        .arrow-img {
          left: 0px;
          right: 0;
          transform: rotate(0deg);
          bottom: -32px;
        }
      }
      `)}      
    }
    .connector-img {
      position: absolute;
      left: 0%;
      top: auto;
      right: 0%;
      bottom: -30px;
      margin-right: auto;
      margin-left: auto;
      ${lg(`
        display: none;
      `)}
    }
  }
`
